import React from "react"
import ReactPlayer from 'react-player'

const Video = ({ videoURL, height }) => {
    return <ReactPlayer
      url={videoURL}
      controls="false"
      height={height}
      width="100%"
      muted="true"
      playing="true"
      loop="true"
    />
}
export default Video