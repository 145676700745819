import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Video from '../components/Video'

import SEO from "../components/SEO"

export const IndexPageTemplate = () => (
<div>
  {/*video component*/}
  <Video videoURL="https://youtu.be/mpOT5lmYI64" height="100vh"/>
</div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <div>
      <SEO uniqueTitle={frontmatter.title} uniqueDescription={frontmatter.description} />
      <Layout image={frontmatter.image} title={frontmatter.title}>
        <IndexPageTemplate />
      </Layout>
    </div>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        description
      }
    }
  }
`
